import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Vuex plugin to persist state to local storage
const localStoragePlugin = store => {
  // Load state from local storage
  const savedState = JSON.parse(localStorage.getItem("store") || "{}");
  store.replaceState(Object.assign(store.state, savedState));

  // Subscribe to store mutations
  store.subscribe((mutation, state) => {
    // Save the state to local storage
    localStorage.setItem("store", JSON.stringify(state));
  });
};

export default new Vuex.Store({
  state: {
    chatOpened: false,
    showAllBotsData: true
  },
  mutations: {
    toggleChat(state, value) {
      state.chatOpened = typeof value === "boolean" ? value : !state.chatOpened;
    },
    toggleBotData(state, value) {
      state.showAllBotsData =
        typeof value === "boolean" ? value : !state.showAllBotsData;
    }
  },
  actions: {},
  modules: {},
  plugins: [localStoragePlugin] // Register the plugin
});
